import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import PageInfo from "../../components/layout/ContentArea/PageInfo";
import { RootState } from "../../redux/store";
import { getChannel, updateChannel } from "../../services/channelService";
import Participant from "./components/Participant";
import { useDispatch } from "react-redux";
import { setRefresh } from "../../redux/features/channelSlice";
import { FaRegEdit } from "react-icons/fa";
import IconButton from "../../components/buttons/IconButton";

const Channel = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);
  const [channel, setChannel] = useState<Channel>();
  const [description, setDescription] = useState("");
  useEffect(() => {
    fetchChannel();
  }, [location.state]);

  const handleClick = async () => {
    const newDescriotion: string = description;

    await updateChannel(location.state.channelId, {
      description: newDescriotion,
    });

    dispatch(setRefresh());
    fetchChannel();
    // return navigate("/");
  };

  const fetchChannel = async () => {
    const result = await getChannel(location.state.channelId);
    setChannel(result.channel);
  };
  
  return (
    <section>
      <PageInfo
        isChannel
        name={channel?.name!}
        image={channel?.image}
        participants={channel?.participants}
      />
      <div className="w-full flex flex-col items-center py-1 xl:py-10">
        <div className="flex xl:flex-row flex-col my-5 p-3 max-w-[800px]">
          <LazyLoadImage
            src={channel?.image}
            alt="user-pp"
            effect="blur"
            className="w-52 h-52 object-cover rounded-full mx-auto mb-5 xl:mb-0"
          />
          <div className="max-w-[400px] lg:w-[800px] md:pl-5">
            <h1 className="text-2xl font-semibold my-2 xl:text-start text-center">
              {channel?.name}
            </h1>
            <p className="min-h-[100px]">
              {channel?.description ? channel.description : "No Information."}
            </p>
          </div>
        </div>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{ backgroundColor: "#818181" }}
          />
          <div>
          <IconButton
              isTextCanClosed
              Icon={FaRegEdit}
              text='Edit Description'
              handleClick={() => handleClick()}
              type='button'
            />
          </div>

        <div className="max-w-[800px] w-full flex flex-col items-start p-3 overflow-y-auto border-t border-neutral-600 pt-10">
          {channel &&
            channel?.participants.map((participant) => {
              return (
                <Participant
                  key={participant.id}
                  participant={participant}
                  isAdmin={channel.admins?.includes(participant.id)!}
                />
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default Channel;

import { CiFileOn } from 'react-icons/ci';

const DownloadFileIcon = (props: any) => {
    const parts = props.url.split('/');
    const fileName = parts[parts.length - 1];

    return (
        <a href={props.url} className="file-link">
            <CiFileOn size={32}/> {fileName}
        </a>
    );
};

export default DownloadFileIcon;

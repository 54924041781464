import { useState } from 'react';
import ReactPlayer from 'react-player';

interface VideoBlockProps {
  url: string;
  playing: boolean;
}

const VideoBlock: React.FC<VideoBlockProps> = ({ url, playing }) => {
  const handleReady = () => {
    // console.log('Відеоплеєр готовий');
  };

  return (
        <ReactPlayer
          url={url}
          width="auto"
          height="300px"
          playing={playing}
          controls
          onReady={handleReady}
        />
  );
};

export default VideoBlock;

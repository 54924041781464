import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PageInfo from "../../components/layout/ContentArea/PageInfo";
import { getUser } from "../../services/userService";
import Info from "./components/Info";

const Profile = () => {
    const location = useLocation();
    const [profileInfo, setProfileInfo] = useState<User>();
    const { id } = useParams();

    useEffect(() => {
        const fetchDetails = async () => {
            const result = await getUser(id || location.state.userId);
            // console.log(result);
            setProfileInfo(result.user);
        }
        fetchDetails();
    }, [id, location]);

    return (
        <section>
            <PageInfo isChannel={false} name={`${profileInfo?.username}'s Profile`} />
            <Info details={profileInfo!} />
            {/* <Tabs profileId={id!} /> */}
        </section>
    )
}

export default Profile;
import moment from "moment";
import { FC, useRef, useState } from "react";
import { HiOutlineChevronDown } from "react-icons/hi";

import { useSelector } from "react-redux";

import { updateMessage } from "../../../services/messageService";
import { RootState } from "../../../redux/store";
import ReactPlayer from "react-player";
import Spinner from "../../../components/loading/Spinner";
import VideoBlock from "./Video";
import DownloadFileIcon from "./File";

type Props = {
  message: Message;
};

const getFileType = (fileExtension: string) => {
  switch (fileExtension) {
    case "jpg":
    case "jpeg":
    case "png":
      return "photo";
    case "mp4":
    case "avi":
    case "mov":
      return "video";
    default:
      return "document";
  }
};

const Message: FC<Props> = ({ message }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deleted, setDeleted] = useState(false);
  const [playing, setPlaying] = useState(false);

  function handlePlayPause() {
    setPlaying(!playing);
  }

  const handleDelete = async () => {
    const messageDoc = {
      images: null,
      text: "This message has been deleted.",
    };
    await updateMessage(message.id, messageDoc);
    setDeleted(true);
    setIsOpen(false);
  };

  const imageRef = useRef<HTMLImageElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
  };
  const renderFileContent = (index: number, url: string) => {
    const fileType = getFileType(url.substring(url.lastIndexOf(".") + 1));

    if (fileType === "photo") {
      return (
        <div className="relative">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <Spinner size={"sm"} />
            </div>
          )}
          <img
            ref={imageRef}
            src={url}
            alt="message"
            onLoad={handleImageLoaded}
            onError={handleImageError}
            style={{
              maxHeight: "300px",
              display: isLoading ? "none" : "block",
            }}
          />
        </div>
      );
    } else if (fileType === "video") {
      return (
        <div>
          <VideoBlock url={url} playing={playing} />
        </div>
      );
    } else if (fileType === "document") {
      return (
        <DownloadFileIcon url={url}></DownloadFileIcon>
      )
    } else {
      return null;
    }
  };
  // console.log(message);
  return (
    <div
      className={`
                rounded-md w-fit p-3 m-3 flex flex-col relative group
                ${
                  message.userId === user?.id
                    ? "bg-cyan-600 ml-auto"
                    : "bg-neutral-900"
                }
            `}
    >
      {message.user?.id === user?.id &&
        message.text !== "This message has been deleted." &&
        !deleted && (
          <div className="absolute hidden group-hover:block top-2 right-1 z-30 w-[98%] bg-[rgba(8,145,178,.7)] transition-all duration-200 max-h-[300px]">
          </div>
        )}
      {message.images &&
        message.images!.length > 0 &&
        !deleted &&
        message.images.map((image: string, index) => {
          return renderFileContent(index, image);
        })}
      <p>{deleted ? "This message has been deleted." : message.text}</p>
      <div
        className={`flex justify-between  ${
          message.userId === user?.id ? "text-neutral-300" : "text-neutral-400"
        }`}
      >
        <p className="mr-3">
          {message.user?.username !== user?.username && message.user?.username}
        </p>
        <p>
          {moment(message.createdAt).isSame(Date.now(), "day")
            ? moment(message.createdAt).format("HH:mm")
            : moment(message.createdAt).format("DD MMM HH:mm")}
        </p>
      </div>
    </div>
  );
};

export default Message;

import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from "../../assets/brand-logo.png";

import socket from "../../lib/socket";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    socket.on("chat", (data) => {
      handleMessage(data);
    });

    // return () => {
    //   socket.off("chat");
    //   socket.removeListener("chat");
    // };
  }, []);

  const handleMessage = (e: any) => {
    window.location.reload();
  }
  
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="flex flex-col items-center p-3">
        <LazyLoadImage
          src={Logo}
          alt="logo"
          effect="blur"
          className="max-w-[1000px]"
        />
      </div>
    </div>
  );
};

export default Home;
